import { useTranslation } from '@pancakeswap/localization'
import { Flex, Text, useMatchBreakpoints } from '@pancakeswap/uikit'
import { ChainTags } from './ChainTags'
import { MetricsCard } from './MetricsCard'


const Stats = () => {
  const { t } = useTranslation()
  const { isMobile, isSm, isMd } = useMatchBreakpoints()

  return (
    <Flex justifyContent="center" alignItems="center" flexDirection="column" overflow="hidden">
      <Text textAlign="center" lineHeight="110%" fontWeight={600} mb="4px" fontSize={isMobile ? '20px' : '32px'}>
        {t('')}
      </Text>
      <Text
        textAlign="center"
        lineHeight="110%"
        fontWeight={600}
        fontSize={isMobile ? '20px' : '32px'}
        mb={isMobile ? '32px' : '48px'}
      >
        {t('Billidex’s Features')}
      </Text>
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection={isMobile ? 'column' : 'row'}
        width={['100%', '100%', '100%', '800px']}
        style={{ gap: isMobile ? 32 : 50 }}
        mb={isMobile ? '32px' : '48px'}
        flexWrap="wrap"
      >
        <MetricsCard
          width={isSm || isMd ? '100%' : 'auto'}
          title={t('Swap Tokens')}
          description={t('Swap your bep20 tokens on Billidex')}
        />
        <MetricsCard title={t('Add Liquidity')} description={t('Add Liquidity to your projects')} />
        <MetricsCard title={t('Farm and Pools')} description={t('Create and participate in farms and pools.')} prefix="$" />
      </Flex>
      <ChainTags />
      </Flex>
  )
}

export default Stats
