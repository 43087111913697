import { useTranslation } from '@pancakeswap/localization'
import { Button, Flex, Link, OpenNewIcon, Text, useMatchBreakpoints } from '@pancakeswap/uikit'
import useTheme from 'hooks/useTheme'
import { ASSET_CDN } from 'config/constants/endpoints'
import React, { memo, useCallback, useLayoutEffect, useRef } from 'react'
import { styled } from 'styled-components'
import { useDrawCanvas } from '../../hooks/useDrawCanvas'
import { useDrawSequenceImages } from '../../hooks/useDrawSequence'
import { checkIsIOS } from '../../hooks/useIsIOS'
import { useObserverOnce } from '../../hooks/useObserver'




export const CakeSectionMainBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  margin-top: -100px;
  width: 100%;
  padding-left: 8px;
  ${({ theme }) => theme.mediaQueries.lg} {
    margin-top: 50px;
    flex-direction: row;
    width: 936px;
    height: 500px;
  }
`
export const CakeSectionLeftBox = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-direction: column;
  z-index: 1;
  max-width: 100%;
  padding: 16px 24px;
  ${({ theme }) => theme.mediaQueries.lg} {
    max-width: 33%;
    overflow: visible;
    padding: 24px 36px;
  }
`
export const CakeSectionRightBox = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: start;
  max-width: 100%;
  padding: 16px 24px;
  ${({ theme }) => theme.mediaQueries.lg} {
    max-width: 33%;
    padding: 24px 36px;
  }
`




export const CakeSectionCenterBox = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 600px;
    height: 600px;
    background: ${({ theme }) =>
      theme.isDark
        ? `radial-gradient(50% 50% at 50% 50%, rgba(151, 71, 255, 0.4) 0%, rgba(151, 71, 255, 0) 100%)`
        : `background: radial-gradient(50% 50.00% at 50% 50.00%, #FFF 0%, rgba(255, 255, 255, 0.00) 100%)`};
  }
`


const width = 900
const height = 900

const CakeSection: React.FC = () => {
  const { theme } = useTheme()
  const { t } = useTranslation()
  const videoRef = useRef<HTMLVideoElement>()
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const leftRef = useRef<HTMLDivElement>(null)
  const leftLineRef = useRef<HTMLDivElement>(null)
  const rightRef = useRef<HTMLDivElement>(null)
  const played = useRef<boolean>(false)
  const cakeBoxRef = useRef<HTMLDivElement>(null)
  const internalRef = useRef(0)
  const seqIntervalRef = useRef(0)
  const { isMobile } = useMatchBreakpoints()

  useLayoutEffect(() => {
    if (checkIsIOS() || isMobile) return
    const video = document.createElement('video')
    video.autoplay = true
    video.playsInline = true
    video.width = width
    video.src = `${ASSET_CDN}/web/landing/cake-alpha.webm`
    video.muted = true
    videoRef.current = video
  }, [isMobile])

  const { drawImage, isVideoPlaying } = useDrawCanvas(videoRef, canvasRef, internalRef, width, height, () => {
    if (isVideoPlaying.current === false) {
      isVideoPlaying.current = true
      internalRef.current = window.requestAnimationFrame(() => {
        drawImage?.()
      })
      triggerCssAnimation()
    }
  })

  useObserverOnce(cakeBoxRef, () => {
    if (checkIsIOS() || isMobile) {
      if (playing.current === false) {
        playing.current = true
        seqIntervalRef.current = window.setInterval(() => {
          drawSequenceImage(900, 900)
        }, 1000 / 32)
      }
      triggerCssAnimation()
    } else videoRef.current?.play()
  })

  const { drawSequenceImage, playing } = useDrawSequenceImages(
    `${ASSET_CDN}/web/landing/cake-token-sequence`,
    checkIsIOS() || isMobile ? 201 : 0,
    canvasRef,
    seqIntervalRef,
    () => clearInterval(seqIntervalRef.current),
  )

  const triggerCssAnimation = useCallback(() => {
    setTimeout(() => {
      if (leftRef.current) leftRef.current?.classList.add('show')
      if (leftLineRef.current) leftLineRef.current?.classList.add('show')
    }, 1000)
    setTimeout(() => {
      if (rightRef.current) rightRef.current?.classList.add('show')
    }, 2000)
    played.current = true
  }, [])

  const triggerAnimation = useCallback(() => {
    if (played.current) {
      if (leftRef.current) leftRef.current?.classList.add('show')
      if (leftLineRef.current) leftLineRef.current?.classList.add('show')
      if (rightRef.current) rightRef.current?.classList.add('show')
    }
  }, [])

  useLayoutEffect(() => {
    triggerAnimation()
    return () => {
      cancelAnimationFrame(internalRef.current)
      clearInterval(seqIntervalRef.current)
    }
  }, [drawImage, triggerAnimation])

  return (
    <Flex
      flexDirection="column"
      style={{ gap: 32 }}
      marginLeft={isMobile ? '-16px' : '0px'}
      width={isMobile ? 'calc(100% + 32px)' : '100%'}
      overflow={isMobile ? 'hidden' : 'visible'}
    >
      <Text textAlign="center" padding="0px 16px">
        <Text fontSize={['32px', null, null, '40px']} display="inline" fontWeight={600} lineHeight="110%">
          {t('Earn more with')}
        </Text>
        <Text
          fontWeight={600}
          display="inline"
          marginLeft={10}
          color={theme.isDark ? '#A881FC' : theme.colors.secondary}
          fontSize={['32px', null, null, '40px']}
          lineHeight="110%"
        >
          {t('Billicatcoin')}
        </Text>
      </Text>
      <Flex justifyContent="center">
        <Text
          fontSize={['16px', null, null, '20px']}
          fontWeight={600}
          color={theme.isDark ? '#B8ADD2' : '#7A6EAA'}
          textAlign="center"
          lineHeight="110%"
          padding="0px 16px"
        >
          {t(
            'Experience the power of community ownership, global governance, and explore infinite use cases within the Billidex ecosystem',
          )}
        </Text>
      </Flex>
      <Flex justifyContent="center" style={{ gap: 14 }}>
        <Link href="https://billidex.finance/swap?outputCurrency=0x47a9B109Cfb8f89D16e8B34036150eE112572435&chainId=56">
          <Button variant="primary">{t('Buy BCAT')}</Button>
        </Link>
        <Link href="https://docs.billidex.finance/governance-and-tokenomics/cake-tokenomics">
          <Button pl="0" endIcon={<OpenNewIcon color="primary" />} variant="text">
            {t('Learn')}
          </Button>
        </Link>
      </Flex>
     </Flex>
  )
}

export default memo(CakeSection)
