import {
  Text,
  useMatchBreakpoints,
} from '@pancakeswap/uikit'
import React from 'react'
import Marquee from 'react-fast-marquee'
import { styled } from 'styled-components'


const TagWrapper = styled.div`
  display: flex;
  padding: 8px 12px;
  height: 38px;
  justify-content: start;
  align-items: center;
  border-radius: 25px;
  margin-right: 8px;
  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 12px 24px;
    height: 46px;
    border-radius: 25px;
    margin-right: 12px;
  }
`

const StyledMarquee = styled(Marquee)`
  width: 100%;
  max-width: 1063px;
  mask-image: linear-gradient(to left, transparent, black 80px, black calc(100% - 80px), transparent);
  -webkit-mask-image: linear-gradient(to left, transparent, black 80px, black calc(100% - 80px), transparent);
  border-radius: 12px;
`


const newsItems = [
  {
    key: 'Swap',
    color: '#f5fafa',
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), #F0B90B',
    iconWidth: '26px',
  },
  {
    key: 'Pools',
    color: '#f5fafa',
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), #333',
    iconWidth: '26px',
  },
  {
    key: 'Earn',
    background: '#17BA92',
    color: '#f5fafa',
    iconWidth: '22px',
  },
  {
    key: 'Game',
    background: '#627AD8',
    iconWidth: '16px',
    color: '#f5fafa',

  },
  {
    key: 'NFT',
    background: '#AD73DE',
    iconWidth: '24px',
    color: '#f5fafa',

  },
  {
    key: 'Billicatcoin',
    background: '#686EA7',
    iconWidth: '26px',
    color: '#f5fafa',
  },
  {
    key: 'Liquidity',
    background: '#6E89AE',
    iconWidth: '20px',
    color: '#f5fafa',

  },
  {
    key: 'Bridge',
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), #81D8EA',
    iconWidth: '18px',
    color: '#f5fafa',

  },
  {
    key: 'Lottery',
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), #75A1FF',
    iconWidth: '20px',
    color: '#f5fafa',

  },
]

export const ChainTags: React.FC = () => {
  const { isMobile } = useMatchBreakpoints()
  return (
    <StyledMarquee>
      {newsItems.map((d) => (
        <TagWrapper style={{ background: d.background }} key={d.key}>
          <Text fontWeight={600} fontSize={isMobile ? '16px' : '20px'} ml="10px" color={d?.color ?? 'invertedContrast'}>
            {d.key}
          </Text>
        </TagWrapper>
      ))}
    </StyledMarquee>
  )
}
